import React from "react";
import { useMount } from "react-use";

const BlogPostRedirect = ({ pageContext }) => {
  const {
    post: { blog_id: blogId, blog_url: blogUrl, locale },
  } = pageContext;

  useMount(() => {
    if (typeof window !== `undefined`) {
      window.location.replace(`/${locale}/blog/${blogId}/${blogUrl}`);
    }
  });

  return <></>;
};

export default BlogPostRedirect;
